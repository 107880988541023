import 'babel-polyfill';

require("./scss/app.scss");

import React from 'react';
import ReactDom from 'react-dom';
import App from './js/components/App.jsx';

let root = document.getElementById("react-root");

ReactDom.render(
    <App />,
    root
);

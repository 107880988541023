import React from "react";

class StartModal extends React.Component {
  constructor() {
    super();
  }
  render() {
    return (
      <div id="modalBack">
        <img src="/images/fleshcanvas.png"/>
        <button onClick={this.props.onStart}>Start</button>
      </div>
    )
  }
}

export default StartModal;

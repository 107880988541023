import React from 'react';
import StartModal from "./StartModal.jsx";
import MouseManager from '../MouseManager.js';

class FleshCanvas extends React.Component {
  constructor() {
    super();
    this._bleeders = [];
    this.state = {
      started: false,
      canvas_id: Math.random()
    }
  }
  componentDidMount() {
    this._mouse = new MouseManager(
      this._can,
      this.didStartDrag.bind(this),
      this.didDrag.bind(this),
      this.didLetGo.bind(this),
      this.didWheel.bind(this),
      this.didMove.bind(this)
    );
    this._loop();
  }
  _reset() {
    this._bleeders = [];
    let ctx = this._can.getContext('2d');
    ctx.fillStyle = "#fde1b7";
    ctx.fillRect(0, 0, this._can.width, this._can.height);
  }
  _loop() {
    setTimeout(this._loop.bind(this), 50);
    let nextBleeders = [];
    this._bleeders.forEach((bleeder) => {
      if (bleeder.bloodLeft <= 0 || bleeder.speed <= 0) {
        return;
      }
      if (bleeder.begin > 0) {
        bleeder.begin--;
        nextBleeders.push(bleeder);
        return;
      }
      let i = 0;
      while (i < bleeder.speed) {
        bleeder.x += this.props.direction.x;
        bleeder.y += this.props.direction.y;
        this._drawBlood(bleeder);
        i++;
      }
      if (Math.random() < .1) {
        let newPoint = {x: bleeder.x, y: bleeder.y };
        let bloodLeft = Math.round(bleeder.bloodLeft * Math.random());
        let speed = Math.round(bleeder.speed * Math.random());
        nextBleeders.push(this._makeBleeder(newPoint, bloodLeft, speed));
      }
      if (Math.random() < .3) {
        bleeder.speed--;
      }
      if (Math.random() < .3) {
        bleeder.bloodLeft--;
      }
      nextBleeders.push(bleeder);
    });
    this._bleeders = nextBleeders;
  }
  didStartDrag(points) {
    this._can.classList.add('dragging');
  }
  didDrag({boxPoint, lastBoxPoint}) {
    let points = this._getPointsBetweenPoints(boxPoint, lastBoxPoint);
    this._drawCut(points);
  }
  didLetGo() {
    this._can.classList.remove('dragging');
  }
  didWheel() {

  }
  didMove() {

  }
  _makeBleeder(point, bloodLeft, speed) {
    bloodLeft = bloodLeft ? bloodLeft : Math.round(Math.random() * 10);
    speed = speed ? speed : Math.round(Math.random() * 3);
    let begin = Math.round(Math.random() * 10);
    return {
      x: point.x,
      y: point.y,
      bloodLeft: bloodLeft,
      speed: speed,
      begin,
      moveTicker: 0,
    };
  }
  _drawCut(points, width = .5) {
    let ctx = this._can.getContext('2d');
    ctx.lineWidth = width;
    ctx.strokeStyle = "rgb(100, 0, 0)";
    let lastPoint = points[0];
    points.forEach((point) => {
      if (width == 2 && Math.random() < .5) {
        this._bleeders.push(this._makeBleeder(point));
      }
      ctx.beginPath();
      ctx.moveTo(lastPoint.x, lastPoint.y);
      ctx.lineTo(point.x, point.y);
      ctx.stroke();
      ctx.closePath();
      lastPoint = point;
    });

    if (width < 2) {
      setTimeout(() => this._drawCut(points, width + .5), 50);
    }
  }
  _drawBlood(bleeder) {
    let ctx = this._can.getContext('2d');
    ctx.beginPath();
    ctx.fillStyle = "red";
    ctx.arc(bleeder.x, bleeder.y, 2, 0, 2 * Math.PI, false);
    ctx.fill();
    ctx.closePath();
  }
  _getPointsBetweenPoints(point1, point2) {
    let diffX = point2.x - point1.x;
    let diffY = point2.y - point1.y;
    let distance = Math.sqrt(diffX**2 + diffY**2);
    let sinVal = diffY / distance;
    let cosVal = diffX / distance;

    let points = [];
    for (let i = 0; i <= distance; i++) {
      let x = point1.x + i * cosVal;
      let y = point1.y + i * sinVal;
      points.push({x, y});
    }
    return points;
  }
  render() {
    let style = {
      backgroundColor: "#ffe7e7",
      marginTop: 35,
    }
    const onStart = () => {
      this.setState({ started: true });
      this._reset();
    }
    const startOver = () => {
      this.setState({ started: false});
    }
    const download = () => {
      var link = document.createElement("a");
      link.style.display = "none";
      document.body.appendChild(link);
      link.download =  'flesh_canvas.png';
      this._can.toBlob((blob) => {
        link.href = URL.createObjectURL(blob);
        link.click();
        document.body.removeChild(link);
      },'image/png');

    }
    return (
      <>
        {
          !this.state.started &&
          <StartModal onStart={onStart}/>
        }
        <header>
          <button onClick={startOver}>Start Again</button>
          <button onClick={download}>Download</button>
        </header>
        <canvas style={style} ref={el => this._can = el} width={this.props.width} height={this.props.height -35} />
      </>
    )
  }
}

export default FleshCanvas;


class MouseManager {
  constructor(draggedBox, didStartDrag, didDrag, didLetGo, didWheel, didMove,) {
    this._origin = null,
    this._last = null,
    this._current = null,
    this._draggedBox = draggedBox;

    this._setMouseMoveListener();
    this._setMouseUpListener();
    this._setMouseDownListener();
    this._setWheelListener();

    this._didStartDrag = didStartDrag;
    this._didDrag = didDrag;
    this._didLetGo = didLetGo;
    this._didWheel = didWheel;
    this._didMove = didMove;

    this._dragging = false;
  }
  _setMouseMoveListener() {
    this._mouseMoveListener = (e) => {
      this._last = this._current;
      this._current = { x: e.clientX || e.touches[0].clientX, y: e.clientY || e.touches[0].clientY };
      // if no origin is set we aren't dragging
      if (!this._dragging) {
        let rect = this._draggedBox.getBoundingClientRect();
        let boxPoint = { x: this._current.x - rect.x, y: this._current.y - rect.y };
        if (this._didMove) {
          this._didMove(boxPoint);
        }
        return;
      } else if (this._dragging) {
        let movement = { x: this._current.x - this._origin.x, y: this._current.y - this._origin.y };
        let rect = this._draggedBox.getBoundingClientRect();
        let boxPoint = { x: this._current.x - rect.x, y: this._current.y - rect.y };
        let lastBoxPoint = this._last ? {x: this._last.x - rect.x, y: this._last.y - rect.y} : null;
        let hyperDragX = 0;
        let hyperDragY = 0;
        if (boxPoint.x < 0 || boxPoint.x >= rect.width) {
          hyperDragX = boxPoint.x % rect.width;
        }
        if (boxPoint.y < 0 || boxPoint.y >= rect.height) {
          hyperDragY = boxPoint.y % rect.height;
        }
        let originToCurrentVector = {x: this._current.x - this._origin.x, y: this._current.y - this._origin.y };
        this._didDrag({
          origin: this._origin,
          last: this._last,
          current: this._current,
          boxPoint,
          lastBoxPoint,
          movement,
          hyperDragX,
          hyperDragY,
          originToCurrentVector,
          wasRightClick: e.button === 2,
        });
      }
    }
    document.body.addEventListener("mousemove", this._mouseMoveListener);
    document.body.addEventListener("touchmove", this._mouseMoveListener);
  }
  _setMouseUpListener() {
    this._mouseUpListener = (e) => {
      if (!this._dragging) {
        return;
      }
      this._dragging = false;
      this._origin = null;
      this._didLetGo({
        wasRightClick: e.button === 2,
      });
    }
    document.body.addEventListener("touchend", this._mouseUpListener);
    document.body.addEventListener("mouseup", this._mouseUpListener);
  }
  _setMouseDownListener() {
    this._mouseDownListener = (e) => {
      this._dragging = true;
      let point = {x: e.clientX || e.touches[0].clientX, y: e.clientY || e.touches[0].clientY };
      this._origin = point;
      this._last = { ...point };
      this._current = { ...point };
      let rect = this._draggedBox.getBoundingClientRect();
      let boxPoint = { x: point.x - rect.x, y: point.y - rect.y };

      this._didStartDrag({
        origin: this._origin,
        last: this._last,
        current: this._current,
        boxPoint: boxPoint,
        movement: {x: 0, y: 0},
        wasRightClick: e.button === 2,
      });
    }
    this._draggedBox.addEventListener("touchstart", this._mouseDownListener);
    this._draggedBox.addEventListener("mousedown", this._mouseDownListener);
  }
  _setWheelListener() {
    this._wheelListener = (e) => {

      this._didWheel({
        movement: {x: Math.round(e.deltaX), y: -Math.round(e.deltaY)}
      });
    }
    this._draggedBox.addEventListener("wheel", this._wheelListener);

  }
  destroy() {
    document.body.removeEventListener("touchmove", this._mouseMoveListener);
    document.body.removeEventListener("mousemove", this._mouseMoveListener);
    document.body.removeEventListener("touchend", this._mouseUpListener);
    document.body.removeEventListener("mouseup", this._mouseUpListener);
    this._draggedBox.removeEventListener("touchstart", this._mouseDownListener);
    this._draggedBox.removeEventListener("mousedown", this._mouseDownListener);
    this._draggedBox.removeEventListener("wheel", this._wheelListener);
    this._draggedBox = null;
    this._didStartDrag = null;
    this._didDrag = null;
    this._didLetGo = null;
  }
}

export default MouseManager;

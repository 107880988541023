import "inobounce";
import React from 'react';
import FleshCanvas from './FleshCanvas.jsx';

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      direction: {x: 0, y: 1},
      ticker: 0,
    }
  }
  render() {
    return (
      <FleshCanvas direction={this.state.direction}  width={window.innerWidth} height={window.innerHeight} />
    )
  }
}

export default App;
